
//==========  Mixins  ==========//

// use these mixins with @includes
// you can pass in different options to the mixin to change the output
// you can also perform logic, etc within mixins to provide different outcomes
// 
// mixins can be used inside media queries



//==========  Debugging  ==========//

// output map keys and values to console
// typically used with map arguments that are passed to mixins
// allows parameters for mixin to be printed to console
@mixin debug($mixin, $params)
{
	@debug '=======  #{$mixin}() options  =======';
	
	// loop through map, ignoring debug key
	@each $key, $value in $params
	{
		@if $key != debug
		{
			@debug '#{$key}	( #{$value} )';
		}
	}
}


//==========  Resets and defaults  ==========//

// use to reset the default browser styling of elements
// usually, this will be form fields - appearance(none)

@mixin appearance($properties) 
{
	-webkit-appearance: $properties;
	-moz-appearance: $properties;
}

// use to change the colour of placeholder text on inputs
// call on parent element, not input itself
// set $self to true for use in ::focus etc
@mixin placeholder($color, $self: false) 
{
		@if $self == false {
			::-webkit-input-placeholder { color: $color; }
			:-moz-placeholder           { color: $color; }
			::-moz-placeholder          { color: $color; }
			:-ms-input-placeholder      { color: $color; }  
		}

		@else {
			&::-webkit-input-placeholder { color: $color; }
			&:-moz-placeholder           { color: $color; }
			&::-moz-placeholder          { color: $color; }
			&:-ms-input-placeholder      { color: $color; }  
		}
	
}


//==========  Font-face helpers  ==========//

// use to import all versions of supported font files
// place these in a directory at the root called /fonts/
// make sure this is noted before the go live as only default directories are moved live

@mixin font-face($family) 
{
	@each $font, $weight in $fonts 
	{  

		@font-face {
			font-family: '#{$family}';
			src: url('../../../fonts/#{$family}-#{$font}-webfont.eot');
			src: url('../../../fonts/#{$family}-#{$font}-webfont.eot?#iefix') format('embedded-opentype'),
				 url('../../../fonts/#{$family}-#{$font}-webfont.woff2') format('woff2'),
				 url('../../../fonts/#{$family}-#{$font}-webfont.woff') format('woff'),
				 url('../../../fonts/#{$family}-#{$font}-webfont.ttf') format('truetype'),
				 url('../../../fonts/#{$family}-#{$font}-webfont.svg##{$family}-#{$font}') format('svg');
			font-weight: $weight;
			font-style: normal;
		}

	}
}


//==========  Alignment helpers  ==========//

// position elements easily - x, y, both axis
// pass in the position (defaults to absolute)
// if you don't want the position being set again, pass in - none

@mixin centreX($position: absolute, $transform: null) 
{
	@if $position != none { position: $position; }

	left: 50%;
	transform: translateX(-50%) $transform;
}

@mixin centreY($position: absolute, $transform: null)
{
	@if $position != none { position: $position; }

	top: 50%;
	transform: translateY(-50%) $transform;
}

@mixin centre($position: absolute, $transform: null) 
{
	@if $position != none { position: $position; }

	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%) $transform;   
}

// sets an element to cover the full width and height of its parent
// defaults to fixed, ideal use for splash screens
// also useful with absolute set for sliders

@mixin coverX($position: fixed) 
{	
	@if $position != none { position: $position; }

	right: 0;
	left: 0;
}

@mixin coverY($position: fixed) 
{	
	@if $position != none { position: $position; }

	top: 0;
	bottom: 0;
}

@mixin cover($position: fixed) 
{	
	@if $position != none { position: $position; }
	
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}


//==========  Visibility helpers  ==========//

// use to toggle the visibility of elements
// typically used in combination with transitions for fade-in / fade-out effect

@mixin hidden() 
{
	visibility: hidden;
	opacity: 0;
}

@mixin visible() 
{
	visibility: visible;
	opacity: 1;
}


//==========  Not Null helpers  ==========//

// use to output a rule, dependant on the value of the variable
// set to 0 or none if not required (no output)
// typically used for setting border-radius rules, if a value is set

@mixin if($variable, $rule) 
{
	@if ( $variable != 0 ) and ( $variable != none )
	{
		#{ map-keys($rule) }: map-values($rule);
	}
}


//==========  Loading helpers  ==========//

// loading animation helpers
// control size and colour of loading dots
// used across modules when using ajax to load content

@mixin loading($color: $color-white, $size: 14px)
{	
	p {
		color: $color;
	}

	.dot {
		width: $size;
		height: $size;
		margin: 0 ($size * 0.5);
		background: $color;
	}
}

// a mixin to loop through a colours map and assign the colour to the property you want 
//defaults to backgrounds with darken hover effect
//Requires Map to work
@mixin colours($map) {

	@if type-of( $map ) != map
	{
		@error 'Please provide a [map] to the colours() mixin';
	}

	$colours-map: 		if( map-has-key( $map, colour ), 	map-get($map, colour), false );
	$initial: 		if( map-has-key( $map, initial ), 	map-get($map, initial), true );
	$hover: 		if( map-has-key( $map, hover ), 	map-get($map, hover), false );
	$active: 		if( map-has-key( $map, active ), 	map-get($map, active), false );
	$rule: 		if( map-has-key( $map, rule ), 	map-get($map, rule), background );

	
	@each $key, $color in $colours-map {
		
		&.#{$key} {

			@if $initial != false {

				@if not( type-of($initial) == 'bool') {
					#{$rule} : $initial;
				}
				@else {
					#{$rule} : $color;
				} 
			}
			
			@if not( $hover == false )  {
			
				@if $initial == true and $hover == true {
					
					&:hover {
						#{$rule} : darken($color, 10%);
					}
				}

				@else  {
					
					&:hover {
						@if not( type-of($hover) == 'bool'){
							#{$rule} : $hover;
						}
						@else {
							#{$rule} : $color;
						} 
					}
				}
			}
			

			@if not( $active == false )  {
			
				@if $initial == true and $active == true {
					
					&:active {
						#{$rule} : darken($color, 10%);
					}
				}

				@else  {
					
					&:active {
						@if not( type-of($active) == 'bool'){
							#{$rule} : $active;
						}
						@else {
							#{$rule} : $color;
						} 
					}
				}
			}
		}
	}
};


@mixin gradient ($color1, $color2) {

	background: rgba($color1,1);
	background: -moz-linear-gradient(left, rgba($color1,1) 0%, rgba($color2,1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba($color1,1)), color-stop(100%, rgba($color2,1)));
	background: -webkit-linear-gradient(left, rgba($color1,1) 0%, rgba($color1,1) 51%, rgba($color2,1) 51%, rgba($color2,1) 100%);
	background: -o-linear-gradient(left, rgba($color1,1) 0%, rgba($color2,1) 100%);
	background: -ms-linear-gradient(left, rgba($color1,1) 0%, rgba($color2,1) 100%);
	background: linear-gradient(to right, rgba($color1,1) 0%, rgba($color2,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$color1, endColorstr=$color2, GradientType=1 );

}
