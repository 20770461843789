
//==========  Import modules  ==========//

@import '../modules/global/config/_index';
@import '../modules/global/utils/_index';
@import '../modules/global/layouts/_index';

$colors: (
	start: $color-primary,
	end: $color-secondary
);

#home {

	h1 {

		@extend %underlined-title;
		@extend %uppercase-text;
		font-size: $font-size-body;
		// line-height: 4rem;
		padding-bottom: $block-padding;

	}

	.hero {
		// background: url('/images/banners/home.jpg') no-repeat center center;
		background-size: cover;
		position: relative;	

		.hero-inner {

			height: 600px;

		}
	}

	.services-buttons {

		// background: $color-primary;
		width: calc(100% - #{$site-padding} * 2);
		position: absolute;
		bottom: -45px;

		.block {

			margin-bottom: 0;
		}

		ul {

			@extend %clear-fix;
		}

		li {			
			position: relative;
			@include gradient ($color-primary, $color-primary-dark);
			border-bottom: 2px solid $color-secondary;

			@media #{$mobileXL} {

				@include fraction(3);

				&:before {

					content: ' ';
					position: absolute;
					top: 0;
					left: 0;
					bottom: 0;
					right: 0;
					width: 100%;
					height: 100%;
					z-index: 0;
					@include gradient ($color-tertiary, $color-primary-dark);
					opacity: 0;
					transition: opacity 0.2s ease-in;
				}

				&:after {

					content: ' ';
					position: absolute;
					left: 0;
					bottom: -4px;
					right:0;	
					width: 100%;
					height: 4px;
					z-index: 0;
					@include gradient ($color-secondary, $color-secondary-dark);
					opacity: 0;
					transition: all 0.3s ease-in;
				}
			}
		
			a {
				color: $color-white;
				display: block;
				height: 90px;
				text-align:center;
				font-style: italic;
				font-family: 'Cormorant Garamond', serif;
				font-size: $font-size-body - 0.1rem;
				padding: $block-padding;
				transition: all 0.2s ease-in;
				position: relative;
				z-index: 2;


				span {
					display: block;
					font-style: normal;
					font-family: $font-family-body;
					@extend %uppercase-text;
					font-size: $font-size-body;
					letter-spacing: 2px;
					
				}		

			}

			&:hover {

				a {

					span {

						color: $color-secondary;
					}
				}

				&:before {
					opacity: 1;
				}

				&:after {
					opacity: 1;
				}
	
			}
		}
	}

	.main {

		padding-top: $block-margin * 1.5;
	}


	.home-testimonials-container {

		background: url('/images/banners/testimonial-bg.jpg') no-repeat center center;
		background-size: cover;
		padding: $block-margin 0;
		position: relative;

		blockquote {
			
			text-align: center;
			margin: 0 $block-margin;
			padding: 0;
			
			p {
				font-size: $h4;
				color: $color-white;
				margin-bottom: $block-padding;
			}

			cite {

				font-size: $font-size-body - 0.2rem;
				font-weight: $font-bold;
				color: $color-primary;
			}
		}

		.block {

			margin-bottom: 0;
		}

		.bx-wrapper {

			position: relative;

		}

		.bx-controls {

			@extend %slider-controls;

			.bx-prev,
			.bx-next {
	
				color: $color-white;
				font-size: $h2;
			
			}
		
		}

		@media #{$tabletXL} {

			blockquote {
			
				margin: 0 $block-margin * 2.5;

				p {
					font-size: $h2;
					color: $color-white;
				}
			
			}
		}
	}
}


.home-slider-container {

	position: absolute;
	width: 100%;
	top: 0;
	z-index: 0;
	
	.block {

		margin-bottom: 0;
	}

	.home-banners {

		.home-banner {

			height: 600px;
			background-repeat: no-repeat;
			background-position: center center;
			background-size: cover;
		}


		@for $i from 0 through 3 {

			.slide-#{$i} {

				background-image: url('/images/banners/slide-#{$i}.jpg') ;
			}
		}

	}
}
